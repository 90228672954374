import { useState, useRef, useEffect } from "react";
import api from "../services/api";
import MDEditor from "@uiw/react-md-editor";
// import { getUrl } from "../services/utils";

export default function EditPostModal({ postId, close }) {
  let [post, setPost] = useState({});
  let [loading, setLoading] = useState(false);
  // let [loadingValidate, setLoadingValidate] = useState(false);
  const inputFile = useRef(null);

  useEffect(() => {
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId]);

  const fetchPost = async () => {
    let post = await api.getBlog(postId);
    if (post.status === 200) setPost(post.data);
  };

  const setValue = (key, value) => {
    let newObj = {};
    if (key === "enabled" && value === true) {
      newObj.publishedDate = new Date();
    }
    newObj[key] = value;
    setPost({ ...post, ...newObj });
  };

  const removeProd = (key) => {
    post._products.splice(key, 1);
    setPost({ ...post });
  };

  const save = async () => {
    if (!post.keywords) post.keywords = [];
    let postObj = { ...post, ...{ keywords: post.keywords.split ? post.keywords.split(",") : post.keywords } };
    let data = await api.savePost(postObj);
    if (data.status === 200) {
      close(data.data);
    }
  };

  const remove = async () => {
    if (await window.confirm("Are you sure?")) {
      let data = await api.removePost(post._id);
      if (data.status === 200) {
        close(data.data);
      }
    }
  };

  const populate = async () => {
    setLoading(true);
    let data = await api.populatePost(post);
    setLoading(false);
    if (data.status === 200) {
      setPost(data.data);
      alert("Completed");
    } else {
      alert("Error");
    }
  };

  const findLinks = async () => {
    setLoading(true);
    let data = await api.findLinks(post);
    setLoading(false);
    if (data.status === 200) {
      setPost(data.data);
      alert("Completed");
    } else {
      alert("Error");
    }
  };

  const writeArticle = async () => {
    setLoading(true);
    let data = await api.writePost(post);
    setLoading(false);
    if (data.status === 200) {
      setPost(data.data);
    } else {
      alert("Error");
    }
  };

  const elaborateArticle = async () => {
    setLoading(true);
    let data = await api.elaboratePost(post);
    setLoading(false);
    if (data.status === 200) {
      setPost(data.data);
    } else {
      alert("Error");
    }
  };

  const selectPhoto = async () => {
    inputFile.current.click();
  };

  const uploadPhoto = async (e, type) => {
    setLoading(true);
    let img = await api.uploadPhoto(e.target.files[0], { type: "blog", name: post.title });
    setLoading(false);
    if (img.status === 200) {
      setValue("image", img.data.fileName);
    } else {
      alert("Error in the upload");
      inputFile.current.value = null;
      inputFile.current.file = null;
    }
  };

  return (
    <>
      <div className="share-modal-backdrop">
        <div className="share-modal">
          <button className="close-btn" onClick={close}>
            X
          </button>

          <div className="modal-content">
            <div className="btn-bar">
              <div onClick={save} className="button small">
                Save
              </div>

              <div onClick={elaborateArticle} className="button small black">
                Elaborate Article
              </div>

              <div onClick={writeArticle} className="button small black">
                Write Article
              </div>

              <div onClick={findLinks} className="button small black">
                Find products link
              </div>

              <div onClick={populate} className="button small black">
                Populate
              </div>

              {post._id && (
                <div onClick={() => selectPhoto()} className="button small black">
                  Change image
                </div>
              )}

              {post._id && (
                <div onClick={remove} className="button small gray">
                  Delete
                </div>
              )}

              <input type="file" ref={inputFile} onChange={uploadPhoto} style={{ display: "none" }} />
            </div>

            {loading && <div>Loading...</div>}

            <div>
              <div className="label-row">
                <label>Title</label>
                <input value={post.title} onChange={(e) => setValue("title", e.target.value)}></input>
              </div>
              <div className="label-row">
                <label>URL</label>
                <input value={post.urlPost} onChange={(e) => setValue("urlPost", e.target.value)}></input>
              </div>
              <div className="label-row">
                <label>Image</label>
                <input value={post.image} onChange={(e) => setValue("image", e.target.value)}></input>
              </div>
              <div className="label-row">
                <label>Description</label>
                <input value={post.metaDescription} onChange={(e) => setValue("metaDescription", e.target.value)}></input>
              </div>
              <div className="label-row">
                <label>Keywords</label>
                <input value={post.keywords} onChange={(e) => setValue("keywords", e.target.value)}></input>
              </div>
              <div className="label-row">
                <label>Valid</label>
                <input type="checkbox" checked={post.valid} onChange={(e) => setValue("valid", e.target.checked)}></input>
              </div>
              <div className="label-row">
                <label>Enabled</label>
                <input type="checkbox" checked={post.enabled} onChange={(e) => setValue("enabled", e.target.checked)}></input>
              </div>
              <div className="label-row">
                <label>Products</label>
                <div className="products-row">
                  {post._products &&
                    post._products.map((prod, i) => (
                      <div key={i}>
                        <div className="button small link" onClick={(e) => removeProd(i)}>
                          Remove
                        </div>
                        <a
                          rel="noreferrer"
                          href={process.env.REACT_APP_APP_URL + "/products/" + prod.token + "/" + prod.urlProduct}
                          target="_blank"
                          referrerPolicy="no-referrer-when-downgrade"
                        >
                          {prod.name}
                        </a>
                      </div>
                    ))}
                </div>
              </div>
              <div className="label-row">
                <label>Content</label>
                <div className="container-markdown">
                  <MDEditor height="400px" value={post.content} onChange={(e) => setValue("content", e)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
