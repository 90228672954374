import useTracking from "../services/useTracking";

export default function Footer() {
  const tracking = useTracking("Footer");

  return (
    <footer className="border-t" sectioncomponent="MixoFooter">
      <div className="mx-auto max-w-7xl overflow-hidden py-16 px-6 sm:py-24 lg:px-8 space-y-8">
        {/* <!-- Links --><!-- Socials --> */}
        <div className="flex flex-wrap justify-center gap-x-6 gap-y-4">
          <a
            href="https://www.instagram.com/ihelptheocean/"
            className="text-gray-400 hover:text-brand-primary"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              tracking("Instagram");
            }}
          >
            <span className="sr-only">instagram</span>
            <svg width="1.2em" height="1.2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" className="h-6 w-6">
              <path
                fill="currentColor"
                d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3Z"
              ></path>
            </svg>
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100091749091534"
            className="text-gray-400 hover:text-brand-primary"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              tracking("Facebook");
            }}
          >
            <span className="sr-only">facebook</span>
            <svg width="1.2em" height="1.2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" className="h-6 w-6">
              <path
                fill="currentColor"
                d="M12 2.04c-5.5 0-10 4.49-10 10.02c0 5 3.66 9.15 8.44 9.9v-7H7.9v-2.9h2.54V9.85c0-2.51 1.49-3.89 3.78-3.89c1.09 0 2.23.19 2.23.19v2.47h-1.26c-1.24 0-1.63.77-1.63 1.56v1.88h2.78l-.45 2.9h-2.33v7a10 10 0 0 0 8.44-9.9c0-5.53-4.5-10.02-10-10.02Z"
              ></path>
            </svg>
          </a>
          <a
            href="https://twitter.com/ihelptheocean"
            className="text-gray-400 hover:text-brand-primary"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              tracking("Twitter");
            }}
          >
            <span className="sr-only">twitter</span>
            <svg width="1.2em" height="1.2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" className="h-6 w-6">
              <path
                fill="currentColor"
                d="M22.46 6c-.77.35-1.6.58-2.46.69c.88-.53 1.56-1.37 1.88-2.38c-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29c0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15c0 1.49.75 2.81 1.91 3.56c-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07a4.28 4.28 0 0 0 4 2.98a8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21C16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56c.84-.6 1.56-1.36 2.14-2.23Z"
              ></path>
            </svg>
          </a>

          <a
            href="https://www.pinterest.com/ihelptheocean/"
            className="text-gray-400 hover:text-brand-primary"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              tracking("pinterest");
            }}
          >
            <span className="sr-only">Pinterest</span>
            <svg width="1.2em" height="1.2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 504 504" className="h-6 w-6">
              <path
                fill="currentColor"
                d="M377.6,0H126C56.8,0,0,56.8,0,126.4V378c0,69.2,56.8,126,126,126h251.6c69.6,0,126.4-56.8,126.4-126.4V126.4
                C504,56.8,447.2,0,377.6,0z M277.2,322c-20,0-39.2-10.4-45.6-22.4c0,0-10.8,41.6-13.2,49.6c-8,28.4-32,56.8-33.6,59.2
                c-1.2,1.6-4,1.2-4.4-1.2c-0.4-3.6-6.4-39.6,0.4-68.8c3.6-14.8,24-98.4,24-98.4s-6-11.6-6-28.4c0-26.8,16-46.8,36-46.8
                c16.8,0,25.2,12.4,25.2,27.2c0,16.4-10.8,41.2-16.4,64c-4.8,19.2,10,34.8,29.6,34.8c35.2,0,59.2-44,59.2-96
                c0-39.6-27.6-69.2-77.6-69.2c-56.4,0-92,40.8-92,86.4c0,15.6,4.8,26.8,12.4,35.6c3.6,4,4,5.6,2.8,10c-0.8,3.2-2.8,11.2-4,14.4
                c-1.2,4.4-5.2,6.4-9.2,4.4c-26-10.4-38-38-38-69.2c0-51.2,44.8-112.8,133.2-112.8c71.2,0,118,50,118,103.6
                C377.6,269.2,337.2,322,277.2,322z"
              ></path>
            </svg>
          </a>

          <a
            href="https://www.tiktok.com/@ihelptheocean"
            className="text-gray-400 hover:text-brand-primary"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              tracking("tiktok");
            }}
          >
            <span className="sr-only">TikTok</span>
            <svg width="1.2em" height="1.2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512" className="h-6 w-6">
              <path
                fill="currentColor"
                d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"
              ></path>
            </svg>
          </a>

          <a
            href="https://www.youtube.com/@ihelptheocean"
            className="text-gray-400 hover:text-brand-primary"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              tracking("youtube");
            }}
          >
            <span className="sr-only">Youtube</span>
            <svg
              width="1.2em"
              height="1.2em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 366.259 366.259"
              className="h-6 w-6"
            >
              <path
                fill="currentColor"
                d="M351.479,71.073c-2.047-7.833-8.614-13.659-16.634-14.758c-50.333-6.899-101.384-10.397-151.737-10.397
                c-50.352,0-101.392,3.498-151.697,10.398c-8.02,1.1-14.586,6.926-16.633,14.758c-19.704,75.403-19.704,148.711,0,224.113
                c2.047,7.832,8.614,13.659,16.635,14.758c50.339,6.899,101.394,10.397,151.745,10.397c50.353,0,101.389-3.498,151.688-10.398
                c8.02-1.1,14.585-6.925,16.632-14.756C371.186,219.794,371.186,146.486,351.479,71.073z M247.165,197.261l-74.357,57.131
                c-3.172,2.439-7.006,3.689-10.86,3.689c-2.681,0-5.374-0.604-7.875-1.835c-6.089-3-9.942-9.198-9.942-15.986V125.999
                c0-6.789,3.854-12.987,9.942-15.986c6.091-3,13.352-2.281,18.735,1.854l74.357,57.13c4.393,3.373,6.966,8.596,6.966,14.132
                C254.13,188.665,251.557,193.888,247.165,197.261z"
              ></path>
            </svg>
          </a>
        </div>
        <p className="text-center text-xs leading-5 text-gray-500">© 2023. All rights reserved.</p>
      </div>
    </footer>
  );
}
