import ReactGA from "react-ga4";

const useAnalyticsEventTracker = (category = "IHelpTheOceanTracking") => {
  const eventTracker = (action, label, value) => {
    ReactGA.event({ category, action, label, value });

    console.log(action);
    if (action === "Click buy link") {
      window.pintrk("track", "checkout", {
        value: 2,
        order_quantity: 1,
        currency: "USD",
        line_items: [
          {
            product_name: value.name,
            product_id: value._id,
          },
        ],
      });
    }

    if (action === "Click on Copy") {
      window.pintrk("track", "lead", {
        value: 1,
        order_quantity: 1,
        currency: "USD",
        line_items: [
          {
            product_name: value.name,
            product_id: value._id,
          },
        ],
      });
    }
  };
  return eventTracker;
};
export default useAnalyticsEventTracker;
