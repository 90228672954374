import { useState } from "react";
import api from "../services/api";
import Table from "./Table";

export default function UploadModal({ data, close }) {
  let [error, setError] = useState();

  const config = {
    columns: [
      {
        key: "name",
      },
      {
        key: "url",
        template: (item) => (
          <a target="_blank" rel="noreferrer" href={item.url}>
            {item.url}
          </a>
        ),
      },
      {
        key: "description",
      },
      {
        key: "image",
      },
      {
        key: "longDesc",
      },
      {
        key: "tags",
      },
    ],
  };

  const upload = async () => {
    let res = await api.uploadProducts(data);

    if (res.status === 200) {
      close(res.data);
      setError("");
    } else {
      alert("Upload error, this shouln't happen! :-( check the error in console");
      console.error(data);
    }
  };

  return (
    <div className="share-modal-backdrop">
      <div className="share-modal">
        <div className="modal-content">
          <button className="close-btn" onClick={close}>
            X
          </button>
          <h4>Import Products</h4>

          <div className="btn-bar">
            <div onClick={upload} className="button small">
              Submit
            </div>
            <div onClick={close} className="button small black">
              Cancel
            </div>
          </div>

          {error && <div className="error-container">{error}</div>}

          <div className="table-container">
            <Table config={config} data={data}></Table>
          </div>
        </div>
      </div>
    </div>
  );
}
