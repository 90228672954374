import React, { useEffect } from "react";
import useTracking from "../services/useTracking";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

const TITLE = "I Help The Ocean - Submit product confirm";

export default function SubmitConfirm({ navigator }) {
  const tracking = useTracking("Submit");

  useEffect(() => {
    const query = new URLSearchParams(document.location.search);
    tracking("Plan_bought", "Plan " + query.get("plan") + " impressions", parseInt(query.get("cost")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="submit-confirm-container">
        <h2 className="mb-12 text-4xl font-bold text-gray-900">Congrats you product is reaching many eco lovers</h2>

        <Link to="/">
          <button onClick={() => (window.location = "/")}>Go back to Home</button>
        </Link>
      </div>
    </>
  );
}
