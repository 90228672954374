export const getUrl = function (prod) {
  if (prod) return process.env.REACT_APP_SHORT_URL + "/p/" + prod.token + "/" + slug(prod.name);
};

export const getUrlPost = function (post) {
  if (post) return process.env.REACT_APP_APP_URL + "/blog/" + post._id + "/" + post.urlPost;
};

function slug(str) {
  return str
    ? str
        .toLowerCase()
        .replace(/[^\w ]+/g, "")
        .replace(/ +/g, "-")
    : "share";
}
