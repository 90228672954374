import { useEffect, useState } from "react";
import api from "../services/api";
import useTracking from "../services/useTracking";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";

export default function BlogList() {
  const [posts, setPosts] = useState([]);
  const tracking = useTracking("Blog List");

  useEffect(() => {
    fetchProds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProds = async () => {
    const res = await api.getBlogPosts();
    setPosts(res.data);
  };

  return (
    <>
      <Header></Header>
      <div className="products-list-page">
        <h1>Blog</h1>

        {posts.map((post, i) => (
          <div className="product-container" key={i}>
            {post.image && <img className="product-image" src={post.image} alt={post._product && post._product.name} />}
            <div className="product-body">
              <Link
                to={"/blog/" + post._id + "/" + post.urlPost}
                className="product-name"
                onClick={() => tracking("Click blog post link", "Blog Post " + post._id)}
              >
                {post.title}
              </Link>
              <div className="product-description">{post.metaDescription}</div>
            </div>
          </div>
        ))}
      </div>
      <Footer></Footer>
    </>
  );
}
