import { useEffect, useState } from "react";
import api from "../services/api";
import { Link } from "react-router-dom";
import useTracking from "../services/useTracking";

// Imgs
import female11 from "../images/female-11.webp";
import male26 from "../images/male-26.webp";
import productsImg from "../images/products.webp";
import pollutionImg from "../images/pollution.webp";
import scocialImg from "../images/ihelptheocean.webp";
import seaImg from "../images/sea.webp";

// Components
import Footer from "../components/Footer";
import Header from "../components/Header";
import Share from "../components/Share";
import { Helmet } from "react-helmet";

export default function Home() {
  const tracking = useTracking("Home");
  const [product, setProduct] = useState({});
  const [message, setMessage] = useState("");

  useEffect(() => {
    generateMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateMessage = async () => {
    tracking("Generate Message");
    const msg = await api.generateMessage();
    setProduct(msg.data);
  };

  const scrollToShare = () => {
    const element = document.getElementById("share-section");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Helmet>
        <meta property="og:url" content="https://ihelptheocean.com/" />
        <meta property="og:title" content="I Help The Ocean | Clean Seas, One Share at a Time" />
        <meta property="og:site_name" content="I Help The Ocean" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Support Ocean-Cleaning Projects with every share. Share an ad of eco-friendly products on social media and help fund ocean conservation projects."
        />
        <meta property="og:image" content="https://ihelptheocean.com/post.png" />
        <meta property="og:image:alt" content="I Help The Ocean | Clean Seas, One Share at a Time" />
        <meta property="twitter:url" content="https://ihelptheocean.com/" />
        <meta property="twitter:title" content="I Help The Ocean | Clean Seas, One Share at a Time" />
        <meta
          property="twitter:description"
          content="Support Ocean-Cleaning Projects with every share. Share an ad of eco-friendly products on social media and help fund ocean conservation projects."
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:image:src" content="https://ihelptheocean.com/post.png" />
      </Helmet>
      <div id="app">
        <Header></Header>
        <div>
          <div className="site-content bg-white overflow-hidden" data-testid="site-content">
            <section
              id="077vpgiirq"
              className="overflow-hidden sm:pt-12 lg:relative lg:py-48"
              sectionname="hero"
              imagestretch="true"
              sectioncomponent="Hero"
              sortorder="0"
              style={{ paddingTop: 0 }}
            >
              <div className="max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
                <div className="relative z-[1]">
                  <div className="mt-14">
                    <div className="mt-6 sm:max-w-xl">
                      <h1 className="text-4xl font-black tracking-tight text-gray-900 sm:text-4xl md:text-5xl">
                        Clean the ocean with every share
                      </h1>
                      <h2 className="mt-6 text-lg text-gray-500 sm:text-xl">
                        Share a post of an eco-friendly product on your social media and you will contribute to donations to
                        ocean-cleaning projects
                      </h2>
                    </div>
                    <div className="mt-10 space-y-4 flex justify-center sm:flex-star">
                      <button
                        to
                        className="bold ui-button ui-button-base ui-button-primary"
                        type="submit"
                        onClick={scrollToShare}
                      >
                        Act now
                      </button>
                    </div>
                    {/* <!-- Social Proofing --><!-- User Review --> */}
                    <div className="mt-6">
                      <div className="inline-flex items-center">
                        <img
                          src={female11}
                          alt="Mia Partridge"
                          className="object-cover inline-block mr-3 border-2 border-primary rounded-full sm:mr-2 h-14 w-14"
                        />
                        <div>
                          <p className="sm:pl-2.5 text-base font-black tracking-tight text-gray-800 sm:text-lg">
                            “Simple and effective way to make a difference.”
                          </p>
                          <p className="sm:pl-2.5 text-sm sm:text-base font-bold text-gray-500">Mia Partridge</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sm:pl-6">
                <div className="pt-1 py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                  <div className="hidden sm:block">
                    <div className="absolute inset-y-0 w-screen left-1/2 bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full"></div>
                    <svg
                      className="absolute -mr-3 top-8 right-1/2 lg:m-0 lg:left-0"
                      width="404"
                      height="392"
                      fill="none"
                      viewBox="0 0 404 392"
                    >
                      <defs>
                        <pattern
                          id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                          x="0"
                          y="0"
                          width="20"
                          height="20"
                          patternUnits="userSpaceOnUse"
                        >
                          <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor"></rect>
                        </pattern>
                      </defs>
                      <rect width="404" height="392" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect>
                    </svg>
                  </div>
                  <div className="relative pl-4 ml-auto sm:max-w-3xl sm:px-0 lg:h-full lg:max-w-none lg:flex lg:items-center xl:pl-12">
                    <img
                      className="w-full rounded-l-3xl lg:w-auto 2xl:h-full 2xl:max-w-none 2xl:rounded-3xl"
                      src={seaImg}
                      alt="I Help The Ocean"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section
              className="px-4 py-12 sm:py-16 sm:px-6 lg:px-8 md:py-24 bg-primary/10"
              sectionname="note"
              id="share-section"
              sectioncomponent="Note"
              sortorder="5"
              site-name="I Help The Ocean"
              site-id="Vtr7F1MAgazR7pX2sRpn"
              site-logo-url="https://ihelptheocean.com/static/media/logo.2c428adddfaa3eb299fa.png"
              site-url="https://ihelptheocean.com/"
              site-logo-size="md"
            >
              <h2 className="mb-6 text-4xl font-bold text-center">Donate without using money</h2>
              <div className="mt-6 mb-4 text-center text-lg text-gray-500 sm:text-xl">
                IHelpTheOcean is a website that makes donations to ocean-cleaning projects every time an eco-friendly product is
                bought from <Link to="/products">our links</Link>.
              </div>
              <h1 className="mb-6 mt-8 text-2xl font-bold text-center">How does it works?</h1>
              <div className="mt-6 mb-0 text-center text-lg text-gray-500 sm:text-xl">
                <div className="how-it-works-content">
                  <div className="number-home">1</div>
                  We generate revenue from affiliate marketing of eco-friendly products, every time a product is bought from{" "}
                  <Link to="/products">our links</Link>.
                </div>
                <div className="how-it-works-content">
                  <div className="number-home">2</div>
                  We use that revenue to make donations to ocean cleaning projects.
                </div>
                <div className="how-it-works-content">
                  <div className="number-home">3</div>
                  You can help by sharing <Link to="/products">our eco-friendly products</Link> on yours social media.
                </div>
              </div>

              <h1 className="mb-6 mt-8 text-2xl font-bold text-center" style={{ marginTop: 40 }}>
                Every little bit helps!
              </h1>

              <div className="mt-6 mb-0 text-center text-lg text-gray-500 sm:text-xl">
                By working together, we can make a difference in protecting the ocean.
              </div>

              <h1 className="mb-6 mt-4 text-4xl font-bold text-center" style={{ marginTop: 40 }}>
                Share a product:
              </h1>

              <div className="max-w-2xl mx-auto overflow-hidden bg-white rounded-lg shadow">
                <div className="px-4 py-5 space-y-4 sm:p-6">
                  <div className="note-text">{message}</div>
                </div>
              </div>

              <div className="message-btn-container">
                <Share product={product} setMessage={setMessage}></Share>
                <button onClick={generateMessage} className="link">
                  Recommend another product
                </button>
              </div>
            </section>

            <div className="mt-20">
              <div>
                <div>
                  <div>
                    <p className="mt-4 text-lg leading-relaxed text-gray-500 sm:text-xl text-center">Follow us on our socials.</p>
                    <p className="text-lg leading-relaxed text-gray-500 sm:text-xl text-center">
                      Share the stories of our products to help promoting them.
                    </p>
                    <div className="flex flex-wrap justify-center gap-x-6 gap-y-4 mt-4">
                      <a
                        href="https://www.instagram.com/ihelptheocean/"
                        className="text-gray-400 hover:text-brand-primary"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          tracking("Instagram");
                        }}
                      >
                        <span className="sr-only">instagram</span>
                        <svg
                          width="1.2em"
                          height="1.2em"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                          className="h-6 w-6"
                        >
                          <path
                            fill="currentColor"
                            d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3Z"
                          ></path>
                        </svg>
                      </a>
                      <a
                        href="https://www.facebook.com/profile.php?id=100091749091534"
                        className="text-gray-400 hover:text-brand-primary"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          tracking("Facebook");
                        }}
                      >
                        <span className="sr-only">facebook</span>
                        <svg
                          width="1.2em"
                          height="1.2em"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                          className="h-6 w-6"
                        >
                          <path
                            fill="currentColor"
                            d="M12 2.04c-5.5 0-10 4.49-10 10.02c0 5 3.66 9.15 8.44 9.9v-7H7.9v-2.9h2.54V9.85c0-2.51 1.49-3.89 3.78-3.89c1.09 0 2.23.19 2.23.19v2.47h-1.26c-1.24 0-1.63.77-1.63 1.56v1.88h2.78l-.45 2.9h-2.33v7a10 10 0 0 0 8.44-9.9c0-5.53-4.5-10.02-10-10.02Z"
                          ></path>
                        </svg>
                      </a>
                      <a
                        href="https://twitter.com/ihelptheocean"
                        className="text-gray-400 hover:text-brand-primary"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          tracking("Twitter");
                        }}
                      >
                        <span className="sr-only">twitter</span>
                        <svg
                          width="1.2em"
                          height="1.2em"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                          className="h-6 w-6"
                        >
                          <path
                            fill="currentColor"
                            d="M22.46 6c-.77.35-1.6.58-2.46.69c.88-.53 1.56-1.37 1.88-2.38c-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29c0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15c0 1.49.75 2.81 1.91 3.56c-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07a4.28 4.28 0 0 0 4 2.98a8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21C16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56c.84-.6 1.56-1.36 2.14-2.23Z"
                          ></path>
                        </svg>
                      </a>

                      <a
                        href="https://www.pinterest.com/ihelptheocean/"
                        className="text-gray-400 hover:text-brand-primary"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          tracking("pinterest");
                        }}
                      >
                        <span className="sr-only">Pinterest</span>
                        <svg
                          width="1.2em"
                          height="1.2em"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 504 504"
                          className="h-6 w-6"
                        >
                          <path
                            fill="currentColor"
                            d="M377.6,0H126C56.8,0,0,56.8,0,126.4V378c0,69.2,56.8,126,126,126h251.6c69.6,0,126.4-56.8,126.4-126.4V126.4
                C504,56.8,447.2,0,377.6,0z M277.2,322c-20,0-39.2-10.4-45.6-22.4c0,0-10.8,41.6-13.2,49.6c-8,28.4-32,56.8-33.6,59.2
                c-1.2,1.6-4,1.2-4.4-1.2c-0.4-3.6-6.4-39.6,0.4-68.8c3.6-14.8,24-98.4,24-98.4s-6-11.6-6-28.4c0-26.8,16-46.8,36-46.8
                c16.8,0,25.2,12.4,25.2,27.2c0,16.4-10.8,41.2-16.4,64c-4.8,19.2,10,34.8,29.6,34.8c35.2,0,59.2-44,59.2-96
                c0-39.6-27.6-69.2-77.6-69.2c-56.4,0-92,40.8-92,86.4c0,15.6,4.8,26.8,12.4,35.6c3.6,4,4,5.6,2.8,10c-0.8,3.2-2.8,11.2-4,14.4
                c-1.2,4.4-5.2,6.4-9.2,4.4c-26-10.4-38-38-38-69.2c0-51.2,44.8-112.8,133.2-112.8c71.2,0,118,50,118,103.6
                C377.6,269.2,337.2,322,277.2,322z"
                          ></path>
                        </svg>
                      </a>

                      <a
                        href="https://www.tiktok.com/@ihelptheocean"
                        className="text-gray-400 hover:text-brand-primary"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          tracking("tiktok");
                        }}
                      >
                        <span className="sr-only">TikTok</span>
                        <svg
                          width="1.2em"
                          height="1.2em"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 512 512"
                          className="h-6 w-6"
                        >
                          <path
                            fill="currentColor"
                            d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"
                          ></path>
                        </svg>
                      </a>

                      <a
                        href="https://www.youtube.com/@ihelptheocean"
                        className="text-gray-400 hover:text-brand-primary"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          tracking("youtube");
                        }}
                      >
                        <span className="sr-only">Youtube</span>
                        <svg
                          width="1.2em"
                          height="1.2em"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 366.259 366.259"
                          className="h-6 w-6"
                        >
                          <path
                            fill="currentColor"
                            d="M351.479,71.073c-2.047-7.833-8.614-13.659-16.634-14.758c-50.333-6.899-101.384-10.397-151.737-10.397
                c-50.352,0-101.392,3.498-151.697,10.398c-8.02,1.1-14.586,6.926-16.633,14.758c-19.704,75.403-19.704,148.711,0,224.113
                c2.047,7.832,8.614,13.659,16.635,14.758c50.339,6.899,101.394,10.397,151.745,10.397c50.353,0,101.389-3.498,151.688-10.398
                c8.02-1.1,14.585-6.925,16.632-14.756C371.186,219.794,371.186,146.486,351.479,71.073z M247.165,197.261l-74.357,57.131
                c-3.172,2.439-7.006,3.689-10.86,3.689c-2.681,0-5.374-0.604-7.875-1.835c-6.089-3-9.942-9.198-9.942-15.986V125.999
                c0-6.789,3.854-12.987,9.942-15.986c6.091-3,13.352-2.281,18.735,1.854l74.357,57.13c4.393,3.373,6.966,8.596,6.966,14.132
                C254.13,188.665,251.557,193.888,247.165,197.261z"
                          ></path>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <section
              className="relative pt-16 pb-32 overflow-hidden bg-white space-y-24"
              sectionname="features"
              id="bg0rhnzj2w"
              sectioncomponent="Features"
              sortorder="1"
              site-name="I Help The Ocean"
              site-id="Vtr7F1MAgazR7pX2sRpn"
              site-logo-url="https://ihelptheocean.com/static/media/logo.2c428adddfaa3eb299fa.png"
              site-logo-size="md"
            >
              <div>
                <div className="lg:mx-auto lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-6 xl:gap-12 2xl:gap-24">
                  <div className="lg:col-start-2 lg:mx-0 lg:px-0 lg:pr-8 max-w-xl px-4 mx-auto space-y-6 sm:px-6 lg:py-28 xl:py-32 2xl:mx-0">
                    {/* <!-- Feature --> */}
                    <div>
                      <h2 className="text-4xl font-extrabold tracking-tight text-gray-900">
                        Combat ocean pollution<span className="text-primary">.</span>
                      </h2>
                      <p className="mt-4 text-lg leading-relaxed text-gray-500 sm:text-xl">
                        With every share, you help fund ocean cleaning and conservation projects, making a tangible impact against
                        pollution.
                      </p>
                    </div>
                    {/* <!-- CTA --> */}
                    <div></div>
                    {/* <!-- Review --> */}
                  </div>
                  {/* <!-- Image --> */}
                  <div className="lg:col-start-1 mt-12 sm:mt-16 lg:mt-0">
                    <div className="pr-4 -sm:ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full lg:flex lg:items-center">
                      <img
                        src={pollutionImg}
                        className="lg:right-0 rounded-r-2xl w-full 2xl:max-h-[44rem]"
                        alt="Combat ocean pollution"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="lg:mx-auto lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-6 xl:gap-12 2xl:gap-24">
                  <div className="lg:mx-auto lg:max-w-3xl xl:pl-12 2xl:pl-20 2xl:justify-self-end max-w-xl px-4 mx-auto space-y-6 sm:px-6 lg:py-28 xl:py-32 2xl:mx-0">
                    {/* <!-- Feature --> */}
                    <div>
                      <h2 className="text-4xl font-extrabold tracking-tight text-gray-900">
                        Promote eco-friendly products<span className="text-primary">.</span>
                      </h2>
                      <p className="mt-4 text-lg leading-relaxed text-gray-500 sm:text-xl">
                        Share ads of <Link to="/products">environmentally friendly products</Link>, encouraging your friends and
                        followers to make conscious and sustainable choices.
                      </p>
                    </div>
                    {/* <!-- CTA --> */}
                    <div></div>
                    {/* <!-- Review --> */}
                  </div>
                  {/* <!-- Image --> */}
                  <div className="mt-12 sm:mt-16 lg:mt-0">
                    <div className="pl-4 sm:-mr-48 sm:-mr-6 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full lg:flex lg:items-center">
                      <img
                        src={productsImg}
                        className="rounded-l-2xl lg:left-0 w-full 2xl:max-h-[44rem]"
                        alt="Promote eco-friendly products"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="lg:mx-auto lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-6 xl:gap-12 2xl:gap-24">
                  <div className="lg:col-start-2 lg:mx-0 lg:px-0 lg:pr-8 max-w-xl px-4 mx-auto space-y-6 sm:px-6 lg:py-28 xl:py-32 2xl:mx-0">
                    {/* <!-- Feature --> */}
                    <div>
                      <h2 className="text-4xl font-extrabold tracking-tight text-gray-900">
                        Be a social media activist<span className="text-primary">.</span>
                      </h2>
                      <p className="mt-4 text-lg leading-relaxed text-gray-500 sm:text-xl">
                        By sharing on social media, you can raise awareness on ocean pollution and inspire others to join you in
                        the fight against it.
                      </p>
                      <p className="mt-4 text-lg leading-relaxed text-gray-500 sm:text-xl">
                        You can also share contents from <a href="https://blog.ihelptheocean.com">our blog</a>
                      </p>
                    </div>
                    {/* <!-- CTA --> */}
                    <div></div>
                    {/* <!-- Review --> */}
                  </div>
                  {/* <!-- Image --> */}
                  <div className="lg:col-start-1 mt-12 sm:mt-16 lg:mt-0">
                    <div className="pr-4 -sm:ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full lg:flex lg:items-center">
                      <img
                        src={scocialImg}
                        className="lg:right-0 rounded-r-2xl w-full 2xl:max-h-[44rem]"
                        alt="Be a social media activist"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="py-12 overflow-hidden bg-primary bg-opacity-80 md:py-20"
              sectionname="testimonials"
              id="d77ctwszcl"
              sectioncomponent="Testimonials"
              sortorder="2"
              site-name="I Help The Ocean"
              site-id="Vtr7F1MAgazR7pX2sRpn"
              site-logo-url="https://ihelptheocean.com/static/media/logo.2c428adddfaa3eb299fa.png"
              site-url="https://ihelptheocean.com/"
              site-logo-size="md"
            >
              <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <svg
                  className="absolute transform top-full right-full translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2 rotate-3"
                  width="404"
                  height="404"
                  fill="none"
                  viewBox="0 0 404 404"
                  role="img"
                  aria-labelledby="svg-squares"
                >
                  <title id="svg-squares">squares</title>
                  <defs>
                    <pattern
                      id="ad119f34-7694-4c31-947f-5c9d249b21f3"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x="0" y="0" width="4" height="4" className="text-primary" fill="currentColor"></rect>
                    </pattern>
                  </defs>
                  <rect width="404" height="404" fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"></rect>
                </svg>
                <div className="relative">
                  <blockquote>
                    <div className="max-w-3xl mx-auto text-xl font-bold leading-7 text-center text-white md:leading-10 md:text-3xl text-shadow-sm">
                      <p>&quot;I feel good knowing my small actions can make big changes.&quot;</p>
                    </div>
                    <footer className="mt-8">
                      <div className="md:flex md:items-center md:justify-center">
                        <div className="md:flex-shrink-0">
                          <img
                            src={male26}
                            className="w-10 h-10 mx-auto border-2 border-slate-200 rounded-full shadow-sm object-cover"
                            alt="Testimonial"
                          />
                        </div>
                        <div className="mt-3 text-center md:mt-0 md:ml-3 md:flex md:items-center text-shadow-sm">
                          <div className="text-lg font-medium text-white">Luke Mings</div>
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              </div>
            </section>
            <section
              className="relative py-12 sm:py-20 md:py-24 lg:py-32"
              sectionname="cta"
              id="se5m9xrjxn"
              sectioncomponent="Cta"
              sortorder="4"
              site-name="I Help The Ocean"
              site-logo-url="https://ihelptheocean.com/static/media/logo.2c428adddfaa3eb299fa.png"
              site-url="https://ihelptheocean.com/"
              site-logo-size="md"
              data-v-77b1cd82
            >
              <div aria-hidden="true" className="block" data-v-77b1cd82>
                <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl mb-12" data-v-77b1cd82></div>
                <svg
                  className="absolute -ml-3 top-8 left-1/2"
                  width="404"
                  height="392"
                  fill="none"
                  viewBox="0 0 404 392"
                  data-v-77b1cd82
                >
                  <defs data-v-77b1cd82>
                    <pattern
                      id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                      data-v-77b1cd82
                    >
                      <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" data-v-77b1cd82></rect>
                    </pattern>
                  </defs>
                  <rect width="404" height="392" fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)" data-v-77b1cd82></rect>
                </svg>
              </div>
              <div className="max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8" data-v-77b1cd82>
                <div
                  className="relative px-6 py-10 overflow-hidden bg-white border-primary border-2 shadow-xl rounded-2xl sm:px-12 sm:py-20"
                  data-v-77b1cd82
                >
                  <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0" data-v-77b1cd82>
                    <svg
                      className="absolute inset-0 w-full h-full"
                      preserveAspectRatio="xMidYMid slice"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 1463 360"
                      data-v-77b1cd82
                    >
                      <path
                        className="text-primary/5"
                        fill="currentColor"
                        d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                        data-v-77b1cd82
                      ></path>
                      <path
                        className="text-primary/10"
                        fill="currentColor"
                        d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                        data-v-77b1cd82
                      ></path>
                    </svg>
                  </div>
                  <div className="relative" data-v-77b1cd82>
                    <div className="flex flex-wrap justify-center w-full mx-auto sm:max-w-3xl" data-v-77b1cd82></div>
                    <div className="mt-6 sm:mt-12 sm:text-center" data-v-77b1cd82>
                      <h2
                        className="text-3xl font-black tracking-tight text-gray-900 sm:text-4xl md:text-5xl md:leading-tight md:max-w-4xl md:mx-auto"
                        data-v-77b1cd82
                      >
                        Share Now to Support I Help The Ocean
                        <span className="text-primary" data-v-77b1cd82>
                          .
                        </span>
                      </h2>
                    </div>
                    <div className="mt-6 sm:mt-12 sm:mx-auto sm:max-w-lg flex flex-col items-center" data-v-77b1cd82>
                      <button
                        to
                        className="bold ui-button ui-button-base ui-button-primary"
                        type="submit"
                        onClick={scrollToShare}
                      >
                        Act now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="px-4 py-12 sm:py-16 sm:px-6 lg:px-8 md:py-24 bg-primary/10"
              sectionname="note"
              id="yajtnuphg5"
              sectioncomponent="Note"
              sortorder="5"
              site-name="I Help The Ocean"
              site-id="Vtr7F1MAgazR7pX2sRpn"
              site-logo-url="https://ihelptheocean.com/static/media/logo.2c428adddfaa3eb299fa.png"
              site-url="https://ihelptheocean.com/"
              site-logo-size="md"
            >
              <h2 className="text-4xl font-bold text-center">Advertise your eco-friendly product</h2>
              <h2 className="mb-6 text-4xl font-bold text-center">reach targeted audience.</h2>
              <div className="max-w-2xl mx-auto overflow-hidden rounded-lg">
                <div className="px-4 py-5 space-y-4 sm:p-6">
                  <div className="mt-6 text-lg text-gray-500 sm:text-xl text-center">
                    If you want to promote your eco-friendly product by contributing to the project, you can&nbsp;
                    <Link
                      to="/submit"
                      onClick={() => {
                        tracking("Click on submit product from Home");
                      }}
                    >
                      submit your product
                    </Link>
                    .
                  </div>
                </div>
              </div>
            </section>

            <Footer></Footer>

            <div className="wrap w-max max-w-xs animate-in fade-in delay-[1000] duration-700" data-v-29401157></div>
          </div>
        </div>
      </div>
    </>
  );
}
