import api from "../services/api";
import useTracking from "../services/useTracking";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import seaImg from "../images/sea.webp";

const TITLE = "I Help The Ocean - Submit product";

export default function Submit() {
  const tracking = useTracking("Submit");

  useEffect(() => {
    const element = document.getElementById("submit-conatiner");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const plans = [
    {
      impressions: 100,
      cost: 10,
    },
    {
      impressions: 1000,
      cost: 50,
      raccomanded: true,
    },
    {
      impressions: 5000,
      cost: 100,
    },
  ];

  const pay = async (plan) => {
    let session = await api.createCheckoutSession(plan);
    if (session.status === 200) window.location.href = session.data.url;
    tracking("Click buy plan", "Impressions " + plan.impressions, plan.cost);
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <Header></Header>

      <div className="site-content bg-white overflow-hidden" id="submit-conatiner" data-testid="site-content">
        <section
          id="077vpgiirq"
          className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48"
          sectionname="hero"
          imagestretch="true"
          sectioncomponent="Hero"
          sortorder="0"
        >
          <div className="max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
            <div className="relative z-[1]">
              <div className="mt-14">
                <div className="mt-6 sm:max-w-xl mt-4 text-lg leading-relaxed text-gray-500 sm:text-xl">
                  <h2 className="mb-12 text-4xl font-bold text-gray-900">Submit your product</h2>
                  <div>
                    By promoting your product on I Help The Ocean, you'll increase your product awareness and contribute to saving
                    the ocean!
                  </div>
                  <div>
                    Part of the revenue from your promotion will be allocated on different projects involving coral restoration,
                    ocean cleaning, and mangrove plantations.
                  </div>

                  <h3 className="mb-6 mt-6 text-4xl font-bold text-gray-900">Only targeted products and audience</h3>
                  <div>
                    We accept only products that are eco-friendly. Since users will share your link with like-minded individuals,
                    friends, and family, your product will be visible to the right audience.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:pl-6">
            <div className="pt-1 py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              <div className="hidden sm:block">
                <div className="absolute inset-y-0 w-screen left-1/2 bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full"></div>
                <svg
                  className="absolute -mr-3 top-8 right-1/2 lg:m-0 lg:left-0"
                  width="404"
                  height="392"
                  fill="none"
                  viewBox="0 0 404 392"
                >
                  <defs>
                    <pattern
                      id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor"></rect>
                    </pattern>
                  </defs>
                  <rect width="404" height="392" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect>
                </svg>
              </div>
              <div className="relative pl-4 ml-auto sm:max-w-3xl sm:px-0 lg:h-full lg:max-w-none lg:flex lg:items-center xl:pl-12">
                <img
                  className="w-full rounded-l-3xl lg:w-auto 2xl:h-full 2xl:max-w-none 2xl:rounded-3xl"
                  src={seaImg}
                  alt="I Help The Ocean"
                />
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="submit-container">
        <div className="submit-text">
          <h2 className="mb-12 text-4xl font-bold text-gray-900">Choose your plan</h2>
          <div className="mt-6 sm:max-w-xl mt-4 text-lg leading-relaxed text-gray-500 sm:text-xl">
            You can select a plan based on the number of impressions that your product will have on our website, the number of
            times users will see your product in a suggested post. We don't have a tracking for clicks since we the link to
            promote as provided by you in the payment form, feel free to submit a{" "}
            <a terget="blank" href="https://bitly.com/">
              bitly
            </a>{" "}
            link or any similiar tracking service if you need more data.
          </div>
        </div>

        <div className="plans-container">
          {plans.map((plan, i) => (
            <div className={"plan" + (plan.raccomanded && " raccomanded")} key={i}>
              <div className="mt-12 text-4xl font-bold text-gray-900 ">{plan.impressions} impressions</div>
              <div className="mt-6 mb-6 text-4xl font-bold text-gray-900">${plan.cost}</div>
              {plan.raccomanded && <div className="raccomanded-text">* Raccomanded</div>}
              <button onClick={() => pay(plan)} className={"plan-button" + (!plan.raccomanded && " black")}>
                Get it now
              </button>
            </div>
          ))}
        </div>
      </div>

      <Footer></Footer>
    </>
  );
}
