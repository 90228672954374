import { useEffect, useState } from "react";
import api from "../services/api";
import useTracking from "../services/useTracking";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { getUrl } from "../services/utils";
import MDEditor from "@uiw/react-md-editor";
import { Helmet } from "react-helmet";

export default function BlogPost() {
  const [post, setPost] = useState({ _product: {} });
  const tracking = useTracking("Product Detail");
  let { id } = useParams();

  useEffect(() => {
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchPost = async () => {
    const res = await api.getBlog(id);
    setPost(res.data);
  };

  return (
    <>
      <Helmet>
        <title>{"I Help The Ocean | " + post.title}</title>
        <meta name="keywords" content={post.keywords && post.keywords.join(",")} />
        <meta name="description" content={post.metaDescription} />
      </Helmet>

      {post._product && (
        <Helmet>
          <meta property="og:type" content="product" />
          <meta property="og:title" content={post.title} />
          <meta property="og:description" content={post.description} />
          <meta property="og:url" content={getUrl(post._product)} />
          <meta property="og:referenced" content={post._product.url} />
          <meta property="og:site_name" content="I Help The Ocean" />
          <meta property="product:price:amount" content={post._product.price} />
          <meta property="product:price:currency" content="USD" />
          <meta property="og:availability" content="instock" />
          <meta property="og:rating" content="5" />
          <meta property="og:rating_scale" content="5" />
          <meta property="og:rating_count" content="196" />

          <meta property="og:image" content={post.image || post._product.image} />
          <meta property="og:image:alt" content={post._product.name} />
          <meta property="twitter:url" content={"https://ihelptheocean.com/products/" + post._product.token + "/eco-friendly"} />
          <meta property="twitter:title" content={post.title} />
          <meta property="twitter:description" content={post._product.description} />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:image:src" content={post.image || post._product.image} />
        </Helmet>
      )}

      <Header></Header>
      <div className="products-detail-page">
        <div style={{ marginBottom: "25px" }}>
          <Link to={"/blog"} className="back-link" onClick={() => tracking("Click back to all prods")}>
            Back to all blog posts
          </Link>
        </div>

        <h1>{post.title}</h1>

        <div className="product-container">
          {(post.image || post._product) && (
            <div className="product-image-conatiner">
              <img className="product-image" src={post.image || post._product.image} alt={post.name} />
            </div>
          )}
          <div className="product-long-description">
            <MDEditor.Markdown linkTarget="_blank" source={post.content} style={{ whiteSpace: "pre-wrap" }} />
          </div>

          <div className="post-footer">
            At "I Help The Ocean", we are dedicated to making a positive impact on our planet. Our mission is to promote
            eco-friendly products that not only enhance your life but also protect our precious oceans. With every purchase you
            make, we actively reinvest revenues into supporting vital ocean-cleaning projects. Together, we can create a cleaner
            and healthier environment for future generations. Take a step towards a sustainable future by exploring our
            thoughtfully curated product list below. Join us in making a difference, one conscious choice at a time. Together, we
            can help the ocean thrive!
          </div>

          <div className="post-footer-btn">
            <Link to={"/products/"} onClick={() => tracking("Click details view products from blog post", "Post " + post._id)}>
              <div className="button">View our eco-friendly products</div>
            </Link>
          </div>

          {post._products && post._products.length > 0 ? (
            <div className="products-list-page" style={{ padding: 0 }}>
              {post._products.map((prod, i) => (
                <div className="product-container" key={i}>
                  <img className="product-image" src={prod.image} alt={prod.name} />
                  <div className="product-body">
                    <Link
                      to={"/products/" + prod.token + "/" + prod.urlProduct}
                      className="product-name"
                      onClick={() => tracking("Click details link", "Prod " + prod._id)}
                    >
                      {prod.name}
                    </Link>
                    <div className="price" style={{ marginLeft: 5, marginTop: 15 }}>
                      {prod.price} <div className="price-symbol">$</div>
                    </div>
                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                    <a
                      href={getUrl(prod)}
                      referrerPolicy="no-referrer-when-downgrade"
                      target="_blank"
                      className="button small product-link"
                      onClick={() => tracking("Click buy link", "Prod " + prod._id, prod)}
                    >
                      Buy on Amazon
                    </a>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            post._product && (
              <>
                <div className="tags-container">
                  {post._product.tags &&
                    post._product.tags.map((tag, i) => (
                      <div className="tag" key={i}>
                        {tag}
                      </div>
                    ))}
                </div>

                <div className="price">
                  {post._product.price} <div className="price-symbol">$</div>
                </div>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a
                  href={getUrl(post._product)}
                  target="_blank"
                  className="button product-link"
                  onClick={() => tracking("Click buy link from blog", "Prod " + post._product._id, post._product)}
                  referrerPolicy="no-referrer-when-downgrade"
                >
                  Buy from Amazon
                </a>
              </>
            )
          )}
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
