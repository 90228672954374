import { create } from "apisauce";
import packageJson from "../../package.json";

export const createApi = () => {
  const api = create({
    baseURL: process.env.REACT_APP_URL_API,
    headers: {
      "Cache-Control": "no-cache",
      "app-version": packageJson.version,
    },
    timeout: 300000,
  });

  const setToken = (token) => {
    if (token) {
      const headerPrefix = "Bearer ";
      const header = "Authorization";
      api.addRequestTransform((request) => {
        request.headers[header] = `${headerPrefix}${token}`;
        return request;
      });
    }
  };

  const login = (username, password) => {
    return api.post(`/api/adminUsers/login`, { username, password });
  };

  const getUser = () => {
    return api.get(`/api/adminUsers/getUser`, {});
  };

  const saveProd = (prod) => {
    return api.post(`/api/product/${prod._id ? prod._id : ""}`, prod);
  };

  const getProducts = () => {
    return api.get(`/api/product`);
  };

  const getProductsAdmin = () => {
    return api.get(`/api/product/list`);
  };

  const getProduct = (id) => {
    return api.get(`/api/product/${id}`);
  };

  const getProductByToken = (token) => {
    return api.get(`/api/product/token/${token}`);
  };

  const generateMessage = () => {
    return api.get(`/api/product/generateMessage`);
  };

  const createCheckoutSession = (plan) => {
    return api.post(`/api/product/createCheckoutSession`, { plan });
  };

  const generateSitemap = () => {
    return api.get(`/api/product/generateSitemap`);
  };

  const uploadProducts = (data) => {
    return api.post(`/api/product/upload`, data);
  };

  const reprashe = (text) => {
    return api.post(`/api/product/reprashe`, { text });
  };

  const uploadPhoto = async (file, params) => {
    let formData = new FormData();
    formData.append("params", JSON.stringify(params));
    formData.append("file", file);

    return api.post(`/api/product/uploadPhotoAdmin`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "multipart/form-data",
      },
    });
  };

  const createRandom = () => {
    return api.post(`/api/blogPost/createRandom`);
  };

  const removePost = (id) => {
    return api.delete(`/api/blogPost/${id}`);
  };

  const getBlog = (id) => {
    return api.get(`/api/blogPost/${id}`);
  };

  const getBlogPosts = () => {
    return api.get(`/api/blogPost`);
  };

  const getBlogPostsAdmin = (isProd) => {
    return api.get(`/api/blogPost/admin?isProd=${isProd}`);
  };

  const savePost = (post) => {
    return api.post(`/api/blogPost/${post._id ? post._id : ""}`, post);
  };

  const populatePost = (post) => {
    return api.post(`/api/blogPost/populate`, post);
  };

  const writePost = (post) => {
    return api.post(`/api/blogPost/writePost`, { post });
  };

  const elaboratePost = (post) => {
    return api.post(`/api/blogPost/elaboratePost`, { post });
  };

  // return functions for interface
  return {
    setToken,
    generateMessage,
    createCheckoutSession,
    login,
    getUser,
    getProducts,
    getProductsAdmin,
    getProduct,
    getProductByToken,
    saveProd,
    uploadPhoto,
    generateSitemap,
    uploadProducts,
    reprashe,
    createRandom,
    removePost,
    getBlog,
    getBlogPosts,
    getBlogPostsAdmin,
    savePost,
    populatePost,
    writePost,
    elaboratePost,
  };
};

// Creates a singleton
export default createApi();
