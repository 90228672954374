import Moment from "react-moment";

export default function StatsProdModal({ product, close }) {
  return (
    <>
      <div className="share-modal-backdrop">
        <div className="share-modal modal-stats">
          <button className="close-btn" onClick={close}>
            X
          </button>

          <div className="modal-content">
            <table>
              <thead>
                <tr>
                  <td>Date</td>
                  <td>Referrer</td>
                  <td>IP</td>
                  <td>Country</td>
                  <td>Location</td>
                  <td>ISP</td>
                  <td>Agent</td>
                </tr>
              </thead>
              <tbody>
                {product.visit &&
                  product.visit.map((view, i) => (
                    <tr key={i}>
                      <td>
                        <Moment format="DD MMM YY hh:mm">{view.date}</Moment>
                      </td>
                      <td>{view.referrer}</td>
                      <td>{view.ip}</td>
                      <td>{view.lookup && view.lookup.country}</td>
                      <td>
                        {view.lookup &&
                          view.lookup.city + ", " + view.lookup.region + ", " + view.lookup.lat + ", " + view.lookup.lng}
                      </td>
                      <td>{view.lookup && view.lookup.isp}</td>
                      <td>{view.agent}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
