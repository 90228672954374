import { useEffect, useState } from "react";
import api from "../services/api";
import useTracking from "../services/useTracking";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Share from "../components/Share";
import { getUrl } from "../services/utils";
import MDEditor from "@uiw/react-md-editor";
import { Helmet } from "react-helmet";

export default function ProductDetail() {
  const [product, setProduct] = useState([]);
  const tracking = useTracking("Product Detail");
  let { id, token } = useParams();

  useEffect(() => {
    fetchProd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, token]);

  const fetchProd = async () => {
    if (id) {
      const res = await api.getProduct(id);
      setProduct(res.data);
    } else if (token) {
      const res = await api.getProductByToken(token);
      setProduct(res.data);
    }
  };

  return (
    <>
      <Helmet>
        <meta property="og:type" content="product" />
        <meta property="og:title" content={product.name} />
        <meta property="og:description" content={product.description} />
        <meta property="og:url" content={getUrl(product)} />
        <meta property="og:referenced" content={product.url} />
        <meta property="og:site_name" content="I Help The Ocean" />
        <meta property="product:price:amount" content="15.00" />
        <meta property="product:price:currency" content="USD" />
        <meta property="og:availability" content="instock" />
        <meta property="og:rating" content="5" />
        <meta property="og:rating_scale" content="5" />
        <meta property="og:rating_count" content="196" />

        <meta property="og:image" content={product.image} />
        <meta property="og:image:alt" content={product.name} />
        <meta property="twitter:url" content={"https://ihelptheocean.com/products/" + product._id} />
        <meta property="twitter:title" content={product.name} />
        <meta property="twitter:description" content={product.description} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:image:src" content={product.image} />
      </Helmet>

      <Header></Header>
      <div className="products-detail-page">
        <div style={{ marginBottom: "25px" }}>
          <Link to={"/products"} className="back-link" onClick={() => tracking("Click back to all prods")}>
            Back to all products
          </Link>
        </div>

        <h1>{product.name}</h1>
        <div className="product-description">{product.description}</div>

        <div className="product-container">
          <div className="product-image-conatiner">
            <img className="product-image" src={product.image} alt={product.name} />
          </div>
          <div className="product-long-description">
            <MDEditor.Markdown source={product.longDesc} style={{ whiteSpace: "pre-wrap" }} />
          </div>

          <div className="tags-container">
            {product.tags &&
              product.tags.map((tag, i) => (
                <div className="tag" key={i}>
                  {tag}
                </div>
              ))}
          </div>

          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            href={getUrl(product)}
            target="_blank"
            className="button product-link"
            onClick={() => tracking("Click buy link", "Prod " + product._id, product)}
            referrerPolicy="no-referrer-when-downgrade"
          >
            Buy from official store
          </a>

          <Share product={product}></Share>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
