import { useEffect, useState } from "react";
import api from "../services/api";
import HeaderAdmin from "../components/HeaderAdmin";
import EditPostModal from "../components/EditPostModal";
import { getUrlPost } from "../services/utils";

export default function AdminBlog() {
  const [post, setPost] = useState();
  const [posts, setPosts] = useState([]);
  const [showEditModal, setShowEditModal] = useState();
  // const [success, setSuccess] = useState();
  // const [errors, setErrors] = useState([]);

  useEffect(() => {
    fetchPost();
    // eslint-disable-next-line
  }, [window.location.pathname]);

  const fetchPost = async () => {
    let posts = await api.getBlogPostsAdmin(window.location.pathname === "/admin/blogProds");
    if (posts.status === 200) setPosts(posts.data);
  };

  const addPost = () => {
    setPost({});
    setShowEditModal(true);
  };

  const editPost = (post) => {
    setPost(post);
    setShowEditModal(true);
  };

  const closeModal = () => {
    setShowEditModal(false);
    fetchPost();
  };

  const importPosts = async () => {
    const text = await navigator.clipboard.readText();
    await api.importPosts(text.split("\n"));
    fetchPost();
  };

  const createRandom = async () => {
    let res = await api.createRandom();
    if (res.status === 200) {
      fetchPost();
    } else {
      alert("Error");
    }
    fetchPost();
  };

  return (
    <>
      <HeaderAdmin></HeaderAdmin>

      <div className="admin-container">
        <h2>Blog</h2>

        <div className="modal-content">
          <div className="btn-bar">
            <div onClick={addPost} className="button small">
              Add Article
            </div>
            <div onClick={importPosts} className="button small">
              Import Article from clipboards
            </div>
            <div onClick={createRandom} className="button small">
              Create Random Post
            </div>
          </div>
        </div>

        {/* {success && <div className="alert-success">{success}</div>}
        {errors.map((err, i) => (
          <div key={i} className="alert-error" dangerouslySetInnerHTML={{ __html: err }}></div>
        ))} */}

        <table>
          <thead>
            <tr>
              <td>Enabled</td>
              <td>Valid</td>
              <td>Title</td>
              <td>Preview</td>
            </tr>
          </thead>
          <tbody>
            {posts.map((post, i) => (
              <tr key={i}>
                <td className="product-views">
                  <input type="checkbox" checked={post.enabled} disabled></input>
                </td>
                <td className="product-views">
                  <input type="checkbox" checked={post.valid} disabled></input>
                </td>
                <td style={{ maxWidth: 400 }} onClick={(e) => editPost(post)}>
                  {post.title}
                </td>
                <td>
                  <a rel="noreferrer" href={getUrlPost(post)} target="_blank" referrerPolicy="no-referrer-when-downgrade">
                    Preview
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {showEditModal && <EditPostModal postId={post._id} close={closeModal}></EditPostModal>}
      </div>
    </>
  );
}
