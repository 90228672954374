import { Link } from "react-router-dom";
import logo from "../images/logo.webp";
import useTracking from "../services/useTracking";

export default function Header() {
  const tracking = useTracking("Header");

  return (
    <div className="header">
      <div className="header-left">
        <Link
          to="/"
          onClick={() => {
            tracking("Click on logo from Header");
          }}
        >
          <img className="header-logo" src={logo} alt="Logo"></img>
          <span className="header-logo-text">I Help The Ocean</span>
        </Link>
      </div>

      <div className="header-center"></div>

      <div className="header-right">
        <div className="header-btn">
          <Link
            to="/submit"
            onClick={() => {
              tracking("Click on submit product from Header");
            }}
          >
            Submit your eco product
          </Link>
        </div>
        <div className="header-btn">
          <Link
            to="/products"
            onClick={() => {
              tracking("Click on products list from Header");
            }}
          >
            Products
          </Link>
        </div>
        <div className="header-btn main">
          <Link
            to="/blog"
            onClick={() => {
              tracking("Click on blog from Header");
            }}
          >
            Blog
          </Link>
        </div>
      </div>
    </div>
  );
}
