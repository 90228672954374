import { useState } from "react";
import { Helmet } from "react-helmet";

// Context
import { useAuth } from "../context/useAuth";

const TITLE = "I Help The Ocean - Admin";

export default function Submit() {
  let { login } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="login-container">
        <h2>Login</h2>

        <div className="login-form">
          <input onChange={(e) => setUsername(e.target.value)} placeholder="username"></input>
          <input onChange={(e) => setPassword(e.target.value)} placeholder="password" type="password"></input>
        </div>
        <button onClick={() => login(username, password)}>Login</button>
      </div>
    </>
  );
}
