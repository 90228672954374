import { useEffect, useState } from "react";
import useTracking from "../services/useTracking";
import {
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon,
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { getUrl } from "../services/utils";

export default function Share({ product, setMessage }) {
  const tracking = useTracking("Share");
  const [showShareModal, setShowShareModal] = useState();

  const hashatgs = "#ecofriendly #plasticfree #oceanconservation #climatechange #sustainability";
  const message = `#ihelptheocean
By sharing this post you are supporting ocean cleaning projects.
Get more info at https://ihelptheocean.com
  
We support eco-friendly products like:
${getUrl(product)}
  
${product.description}`;

  const copy = () => {
    tracking("Click on Copy", "Prod " + product._id, product);

    if (navigator.clipboard) navigator.clipboard.writeText(message + "\n\n" + hashatgs);

    if (navigator.share) {
      alert("Please paste the message in the post.\n\nThe message has aready been copied for you!");
      navigator.share({
        url: getUrl(product),
        text: message,
      });
    } else {
      setShowShareModal(true);
    }
  };

  const close = () => {
    setShowShareModal(false);
  };

  useEffect(() => {
    if (setMessage) setMessage(message);
    // eslint-disable-next-line
  }, [product]);

  return (
    <>
      <button className="button small black" onClick={copy}>
        Share it on my socials
      </button>

      {showShareModal && (
        <div className="share-modal-backdrop">
          <div className="share-modal">
            <button className="close-btn" onClick={close}>
              X
            </button>

            <div className="modal-content">
              <div className="share-btn-text">
                <div>The message has been copied in the clipboard,</div>
                <div>you can paste it or share on your preferred social from here</div>
              </div>

              <div className="share-btn-container">
                <TwitterShareButton title={message} url={" "}>
                  <TwitterIcon></TwitterIcon>
                </TwitterShareButton>
                <FacebookShareButton
                  beforeOnClick={() => {
                    alert(
                      "Please paste the message in the facebook post caption.\n\nThe message has aready been copied for you!"
                    );
                  }}
                  quote={message}
                  hashtag={"#ihelptheocean"}
                  url={getUrl(product)}
                >
                  <FacebookIcon></FacebookIcon>
                </FacebookShareButton>
                <WhatsappShareButton title={message} url={" "} windowWidth="1000" windowHeight="900">
                  <WhatsappIcon></WhatsappIcon>
                </WhatsappShareButton>
                <RedditShareButton
                  title={message}
                  url={getUrl(product)}
                  beforeOnClick={() => {
                    alert("You can also change the type of Reddit sharing from link to post");
                  }}
                >
                  <RedditIcon></RedditIcon>
                </RedditShareButton>
                <LinkedinShareButton
                  title="I Help The Ocean"
                  summary={message}
                  source={process.env.REACT_APP_APP_URL}
                  url={getUrl(product)}
                  beforeOnClick={() => {
                    alert(
                      "Please paste the message in the Linkedin post caption.\n\nThe message has aready been copied for you!"
                    );
                  }}
                >
                  <LinkedinIcon></LinkedinIcon>
                </LinkedinShareButton>
                <EmailShareButton body={message} url={getUrl(product)}>
                  <EmailIcon></EmailIcon>
                </EmailShareButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
