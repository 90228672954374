import React from "react";
import Routes from "./Routes";
import ReactGA from "react-ga4";

// Context
import { ProvideAuth } from "./context/useAuth";
import { BrowserRouter } from "react-router-dom";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

function App() {
  return (
    <div className="main-container">
      <ProvideAuth>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ProvideAuth>
    </div>
  );
}

export default App;
